/**
* Rangeslider
*/
.rangeslider {
    font-weight: bold;
    border: 3px solid #000;
    margin: 38px 0 20px 0;
    position: relative;
    background: #fff;
    -ms-touch-action: none;
    touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
    display: block;
}
.rangeslider .rangeslider__handle {
    background: #FFE8C7;
    border: 3px solid #000;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    box-shadow: 0px 6px 0px 0px rgba(0,0,0,1);
    z-index: 5;
}
.rangeslider .rangeslider__handle:focus{
    outline: none;
}
.rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
    height: 16px;
}
.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #FFE8C7;
    border-radius: 10px;
    top: 0;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    top: 50%;
    transform: translate3d(-56%, -50%, 0);
    cursor: grab;
}

/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
    right: 0;
}
.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
    top: 0;
    bottom: inherit;
}
/**
* Rangeslider - Labels
*/
.rangeslider__labels {
    position: relative;
    margin-top: 4px;
}

.rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 20px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);

}
.rangeslider__labels .rangeslider__label-item:before{
    display: block;
    content: "";
    width: 3px;
    margin: 0 0 0 28px;
    height: 16px;
    background-color: #000;
}
.mailSlider .rangeslider__labels .rangeslider__label-item:first-child:before{
    margin: 0 0 0 14px;
}
.mailSlider .rangeslider__labels .rangeslider__label-item:last-child:before{
    margin: 0 0 0 20px;
}

.chartSlider{
    margin-top: -30px;
    width: 255px;
}
.chartSlider .rangeslider__labels .rangeslider__label-item:last-child:before{
    margin: 0 0 0 18px;
}
