html{
  font-size: 62.5%
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}
strong{
    font-weight: 900;
}
li{
    list-style: none;
}
ul, p{
    margin: 0;
    padding: 0;
}

div{
  box-sizing: border-box;
}
input{
    margin: 0;
}

button{
  border: none;
  padding: 0;
  background-color: transparent;
}

a{
  color: #000;
  text-decoration: none;
}

a:focus, input:focus, button:focus{
  outline: none;
}

tspan{
    pointer-events: none;
    font-weight: 900;
    font-family: "Cera Basic", sans-serif !important;
    transition: fill .2s ease-in;
}

.PieChart svg{
    filter: drop-shadow( 0px 5px 0px #000 );
}
.PieShadow {
    filter: drop-shadow( 0px 5px 0px #000 );
}
.VictoryContainer path {
    cursor: pointer;
    transition: stroke-width .2s ease-in;
}
.VictoryContainer text:last-child {
    transform: translate(14px, -22px);
}
.VictoryContainer text:nth-last-child(2) {
    transform: translateX(-13px);
}
.VictoryContainer text:nth-last-child(3){
    transform: translateX(-16px);
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.LineChart .VictoryContainer {
    transform: translateX(25px);
}
.VictoryContainer {
    transform: translateX(-70px);
}
.firstPie g text:nth-last-child(5){
    transform: translateX(15px);
}
.firstPie g text:nth-last-child(4){
     transform: translate(-28px,24px);
 }
.firstPie g text:nth-last-child(3){
    transform: translate(-27px,-20px)
}
.firstPie g text:nth-last-child(2){
    transform: translate(-80px,-112px)
}
.firstPie g text:nth-last-child(1){
    transform: translate(-65px,-130px)
}

.secondPie g text:nth-last-child(5){
    transform: translate(17px, -66px) rotate(6deg)
}
.secondPie g text:nth-last-child(4){
    transform: translate(44px,-59px) rotate(6deg)
}
.secondPie g text:nth-last-child(3){
    transform: translate(62px,-40px) rotate(7deg)
}
.secondPie g text:nth-last-child(2){
    transform: translate(79px,-25px) rotate(10deg)
}
.secondPie g text:nth-last-child(1){
    transform: translate(-65px,-130px)
}

.whiteSvg svg polyline{
    stroke: #FFF !important;
}

.coloredTitle div:first-child div{
    color: #1C1CAF;
}

/*.coloredTitle:first-child{*/
    /*color: #000;*/
/*}*/

